$(document).ready(function(){
    //alignFooter ();
    var currentSection = location.hash;
    var menuHeight = 100;
    var slickPanel = $('.slider-home-panel');

    //primeira animação dos textos do painél
    slickPanel.on('init', function (event, slick) {
        setTimeout(function() {
            $(slick.$slides.get(0)).find(".text").animate({
              opacity: 1
            },500, function(){
              $(slick.$slides.get(0)).find(".title").addClass('fadeInLeft');
              $(slick.$slides.get(0)).find(".subtitle").addClass('fadeInRight');

              $(slick.$slides.get(0)).find(".btn-blue").addClass('fadeIn');
            });
        }, 500);
      });

    $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
			event.preventDefault();
			event.stopPropagation();
			$(this).parent().siblings().removeClass('open');
			$(this).parent().toggleClass('open');
		});

    /* PANEL */

    slickPanel.slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true
    });

    var panelHeight;

    if($(window).width() >= 1200){
      panelHeight = 700;
    }else{
      panelHeight = $(window).height() - menuHeight * 1.5;
    }


    slickPanel.css('height', panelHeight);
    slickPanel.find('.slick-list').css('height',  panelHeight);
    slickPanel.find('.slick-slide').css('height', panelHeight);
    slickPanel.find('figure').css('height',  panelHeight);

    $(window).resize(function(){
      if($(window).width() >= 1200){
        panelHeight = 700;
        $('nav').removeClass('shrink');
      }else{
        panelHeight = $(window).height() - menuHeight * 1.5;
        $('nav').addClass('shrink');
      }

      $('.slider-home-panel').css('height', panelHeight);
      $('.slider-home-panel').find('.slick-list').css('height',  panelHeight);
      $('.slider-home-panel').find('.slick-slide').css('height',  panelHeight);
      $('.slider-home-panel').find('figure').css('height',  panelHeight);

      alignFooter();
    });

    //quando troca a imagem do painel, anima os elementos de texto
    slickPanel.on('afterChange', function(event, slick, currentSlide, nextSlide){

      $(slick.$slides.get(currentSlide)).find(".text").animate({
        opacity: 1
      },500, function(){
        $(slick.$slides.get(currentSlide)).find(".title").addClass('fadeInLeft');
        $(slick.$slides.get(currentSlide)).find(".subtitle").addClass('fadeInRight');

        $(slick.$slides.get(currentSlide)).find(".btn-blue").addClass('fadeIn');
      });
    });

    /* END PANEL */


    $('button.navbar-toggle').click(function() {
      if($(this).attr('aria-expanded') == 'true'){
        $(this).css('background-color', '#7dabdd');
        $(".navbar-header .bg-mobile").hide();
        $(this).find('.icon-bar').css('background-color', '#2c437d');
      }else{
        $(this).css('background-color', '#2c437d');
        $(this).find('.icon-bar').css('background-color', 'white');
        $(".navbar-header .bg-mobile").show();
      }
    });

    $('#sections').css('opacity', 0);

    setTimeout(function(){
      $('#sections').addClass('fadeInUp');
    }, 1700)


    // //if is home
    // if(window.location.href.split('/')[3] == ""){
    //   var waypoint = new Waypoint({
    //     element: document.getElementById('sections'),
    //     handler: function() {
    //       $('#sections').addClass('fadeInUp');
    //     },
    //     offset: 500
    //   });
    // }

    function alignFooter(){
      var docHeight = $(window).height();
      var footerHeight = $('#footer').outerHeight();
      var footerTop = $('#footer').position().top + footerHeight;
      if (footerTop < docHeight) {
        $('#footer').css('margin-top', 10 + (docHeight - footerTop) + 'px');
      }
    }

    $(window).scroll(function() {
      if ($(document).scrollTop() > 50) {
        $('nav').addClass('shrink');
      } else {
        $('nav').removeClass('shrink');
      }
    });

    setTimeout(function() {
      $("#preloader").animate({
        opacity: 0
      }, 400, function(){
        $(this).hide();
      });
    }, 400);

    $("img.lazy, div.lazy").lazyload({
        effect : "fadeIn"
    });

    // if(currentSection != ""){
    //     var offset = $(currentSection.replace('-link',"")).offset().top - menuHeight;
    //     $("html, body").animate({ scrollTop: offset }, 500);
    // }

    // $('.navbar-nav a').click(function(event){
    //     event.preventDefault();
    //     event.stopPropagation();

    //     var clickedSection = $(this).attr('id').replace('-link',"");

    //     var currentSection = location.href;
    //     if(currentSection.indexOf('blog') != -1){
    //         window.location = "/#" + clickedSection;
    //         return false;
    //     }

    //     if(clickedSection.indexOf('blog') != -1){
    //         window.location = clickedSection;
    //         return false;
    //     }

    //     setTimeout(function() {
    //         var offset = $("#" + clickedSection).offset().top - menuHeight;
    //         $("html, body").animate({ scrollTop: offset }, 500, function(){
    //             location.hash = clickedSection;
    //         });
    //     }, 300);

    // });

});
